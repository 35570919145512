<script setup>
import events from "../assets/gql/events.gql";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const { data } = await useAsyncQuery(events);

// store
const store = useFiltersStore();
const {
  setSelectedDate,
  setSelectedCity,
  setSelectedDropdowns,
  setThemeDropdown,
  setFilteredEvents,
  setEvents,
  setCitiesArray,
  setDatesArray,
  setAllDatesArray,
  setLocationsArray,
  setThemesArray,
  setTargetsArray,
  setFormatsArray,
  filterEvents,
  removeTheme,
} = store;

const selectedCity = computed(() => store.selectedCity);
const selectedDate = computed(() => store.selectedDate);
const selectedThemes = computed(() => store.selectedThemes);
const filteredEvents = computed(() => store.filteredEvents);

const cities = computed(() => store.cities);
const dates = computed(() => store.dates);
const allDates = computed(() => store.allDates);
const locations = computed(() => store.locations);
const themes = computed(() => store.themes);
const targets = computed(() => store.targets);
const formats = computed(() => store.formats);

setEvents(data.value?.events);
setFilteredEvents(data.value?.events);
setDatesArray();
setAllDatesArray();
setCitiesArray();
setLocationsArray();
setThemesArray();
setTargetsArray();
setFormatsArray();

// initial load
if (route.query.city) {
  console.log(route.query.city);
  setSelectedCity(route.query.city);
  filterEvents();
  setDatesArray();
  nextTick(() => {
    const calendar = document.querySelector("#program");
    if (calendar) {
      calendar.scrollIntoView({ behavior: "smooth" });
    }
  });
}

const onCitySelectChange = (city) => {
  setSelectedCity(city);
  setSelectedDate(null);
  filterEvents();

  setThemeDropdown(null);
  setSelectedDropdowns("target", null);
  setSelectedDropdowns("format", null);

  setDatesArray();
  setLocationsArray();
  setThemesArray();
  setTargetsArray();

  if (city) {
    router.push({
      query: { ...route.query, city },
    });
  } else {
    router.push({ query: {} });
  }
};

const onDateChange = (date) => {
  setSelectedDate(date);
  filterEvents();

  setThemeDropdown(null);
  setSelectedDropdowns("target", null);
  setSelectedDropdowns("format", null);

  setDatesArray();
  setLocationsArray();
  setThemesArray();
  setTargetsArray();
};

const filterEventsByDate = (date) => {
  return filteredEvents.value
    .filter((event) => event.term === date)
    .sort((a, b) => (a.start_time > b.start_time ? 1 : -1));
};

function formatDate(dateString) {
  const [year, month, day] = dateString?.split("-");
  return `${parseInt(day)}.${parseInt(month)}.`;
}

function onThemeRemove(theme) {
  removeTheme(theme);
  filterEvents();
  setDatesArray();
}

onUnmounted(() => {
  setSelectedCity(null);
  setSelectedDate(null);

  setThemeDropdown(null);
  setSelectedDropdowns("target", null);
  setSelectedDropdowns("format", null);
});
</script>

<template>
  <section class="bg-gradient">
    <div class="text-container">
      <h2 id="program">Program</h2>

      <div class="flex-between filtering">
        <div>
          <div class="tags cities" v-if="cities">
            <div
              class="tag"
              :class="{ highlighted: null === selectedCity }"
              @click.prevent="onCitySelectChange(null)"
            >
              Všechna města
            </div>

            <div
              v-for="city in cities"
              :key="city"
              class="tag"
              :class="{ highlighted: city === selectedCity }"
              @click.prevent="onCitySelectChange(city)"
            >
              {{ city }}
            </div>
          </div>
          <div class="flex tags">
            <div
              class="tag"
              :class="{ highlighted: null === selectedDate }"
              @click="onDateChange(null)"
            >
              Všechna data
            </div>

            <div
              v-for="date in allDates"
              :key="date"
              class="tag"
              :class="{ highlighted: date === selectedDate }"
              @click.prevent="onDateChange(date)"
            >
              {{ formatDate(date) }}
            </div>
          </div>
        </div>

        <DropDowns
          :themes="themes"
          :locations="locations"
          :targets="targets"
          :formats="formats"
        />
      </div>
      <div class="flex tags" v-if="selectedThemes">
        <div
          v-for="theme in selectedThemes"
          :key="theme"
          class="tag tag-with-x"
          @click="onThemeRemove(theme)"
        >
          {{ theme }}
          <img src="/images/close.svg" />
        </div>
      </div>
      <div v-if="dates.length === 0" class="no-results">
        Podle požadovaného filtru jsme nenašli žádné výsledky.
      </div>
    </div>

    <div class="calendar" id="calendar">
      <div v-for="date in dates" :key="date" class="column">
        <div class="event date">
          <h4>{{ formatDate(date) }}</h4>
        </div>
        <Event v-for="event in filterEventsByDate(date)" :event="event" />
      </div>
    </div>
  </section>
</template>

<style scss>
.calendar {
  width: 100%;
  margin-top: 48px;
  display: flex;
  overflow-x: scroll;
  .column {
    flex: 1;
    max-width: 100%;
    box-sizing: border-box;
    min-width: 16.66vw;
    max-width: 240px;
    @media (max-width: 1200px) {
      min-width: 20vw;
    }
    @media (max-width: 1000px) {
      min-width: 25vw;
    }
    @media (max-width: 800px) {
      min-width: 33.33vw;
    }
    @media (max-width: 600px) {
      min-width: 50vw;
    }
  }
}

.highlighted {
  background-color: black;
  color: white;
  transition-duration: 350ms;
}

.flex-start {
  align-items: flex-start;
  display: flex;
}

.no-results {
  padding-top: 32px;
}
.tags {
  margin: auto -4px;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  .tag {
    padding: 6px 10px;
    border: 0.5px solid black;
    margin: 4px;
    border-radius: 4px;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: black;
      color: white;
      transition-duration: 350ms;
      img {
        filter: invert(1);
      }
      .dropdown__list {
        background-color: white;
        color: black;
      }
    }
  }
  .tag-with-x {
    background-color: black;
    display: flex;
    color: white;
    img {
      filter: invert(1);
      margin-left: 0.5rem;
      max-width: 10px;
    }
    &:hover {
      background-color: transparent;
      color: black;
      transition-duration: 350ms;
      img {
        filter: none;
      }
    }
  }
}
.dropdowns {
  text-align: center;
  .tag {
    padding: 0;
    justify-content: center;
    width: 180px;
  }
  .tag__head {
    padding: 6px 24px;
    justify-content: center;
  }
  .open {
    background-color: white;
    .tag__head {
      border-bottom: 0.5px solid black;
    }
  }
  img {
    margin-left: 8px;
  }

  p {
    padding: 6px;
    margin: 0;
    font-size: 14px;
    &:hover {
      font-weight: 900;
      transition-duration: 350ms;
    }
  }
}

.filterchosen {
  font-weight: 900;
}

.cities {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

/* Add some basic styles and animations */
.tag {
  cursor: pointer;
}

.dropdown__list {
  position: absolute;
  background-color: white;
  border: 0.5px solid black;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 180px;
  margin-left: -0.5px;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.highlighted {
  font-weight: bold;
}

.dropdown.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filtering {
  @media (max-width: 1200px) {
    display: block;
  }
}
</style>
